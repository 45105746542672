import React from "react"
import Sidebar from "../components/layout/sidebar"
import Staff from "../components/contacts/staff"
import WorkingHours from "../components/contacts/working-hours"
import useStaff from "../hooks/use-staff"
import useContacts from "../hooks/use-contacts"

export default function Contacts({ data }) {
  const staffData = useStaff()
  const contactsData = useContacts()

  return (
    <Sidebar
      heading="Контакты"
      leftColumn={<WorkingHours data={contactsData} />}
      sticky
      iconsWithA
    >
      <Staff data={staffData} />
    </Sidebar>
  )
}
